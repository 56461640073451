import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import * as React from 'react';

const Copyright: React.FC = () => {
    return (
        <Typography
            variant="body2"
            align="center"
            sx={{
                color: 'text.secondary',
            }}
        >
            {'Copyright © '}
            <Link color="inherit" href="https://framedghosts.com/">
                Framed Ghosts
            </Link>{' '}
            {new Date().getFullYear()}.
        </Typography>
    );
}

export default Copyright;
