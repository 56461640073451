import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Container, IconButton, Typography } from '@mui/material';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Copyright from '../components/Copyright';
import { Art } from '../types';

interface ArtDetailPageProps {
    artList: Art[];
}

const ArtDetailPage: React.FC<ArtDetailPageProps> = ({ artList }) => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const artId = parseInt(id!, 10);
    const art = artList.find((item) => item.id === artId);

    const handleBackClick = () => {
        navigate('/');
    };

    const handlePrevClick = () => {
        const prevId = artId - 1;
        if (prevId >= 1) {
            navigate(`/art/${prevId}`);
        }
    };

    const handleNextClick = () => {
        const nextId = artId + 1;
        if (nextId <= artList.length) {
            navigate(`/art/${nextId}`);
        }
    };

    if (!art) {
        return <Typography variant="h6" component="div">Art not found</Typography>;
    }

    return (
        <Container>
            <Button variant="contained" color="primary" onClick={handleBackClick}>
                Back to Home
            </Button>
            <Typography variant="h4" component="h1" gutterBottom>
                {art.title}
            </Typography>
            <Box position="relative">
                <img src={art.image} alt={art.title} style={{ width: '100%', objectFit: 'cover' }} />
                {artId > 1 && (
                    <IconButton onClick={handlePrevClick}
                        style={{ position: 'absolute', top: '50%', left: 0, transform: 'translateY(-50%)' }} >
                        <ArrowBackIosIcon />
                    </IconButton>
                )}
                {artId < artList.length && (
                    <IconButton onClick={handleNextClick}
                        style={{ position: 'absolute', top: '50%', right: 0, transform: 'translateY(-50%)' }} >
                        <ArrowForwardIosIcon />
                    </IconButton>
                )}
            </Box>
            <Typography variant="h5" component="h2">
                {art.artist}
            </Typography>
            <Typography variant="body1" component="p">
                Detailed description of the art piece goes here.
            </Typography>
            <Typography variant="body1" component="p">
                <Copyright />
            </Typography>
        </Container>
    );
};

export default ArtDetailPage;
