import { Card, CardContent, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Art } from '../types';

interface ArtCardProps {
    art: Art;
}

const ArtCard: React.FC<ArtCardProps> = ({ art }) => {
    const navigate = useNavigate();
    const handleImageClick = () => { navigate(`/art/${art.id}`) };
    return (
        <Card sx={{ width: 300 }}>
            <CardMedia
                component="img"
                height="140"
                image={art.image}
                alt={art.title}
                sx={{ width: '100%', objectFit: 'cover', cursor: 'pointer' }}
                onClick={handleImageClick}
            />
            <CardContent>
                <Typography variant="h5" component="div" noWrap>
                    {art.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" noWrap>
                    {art.artist}
                </Typography>
            </CardContent>
        </Card >
    );
}

export default ArtCard;
