import React, { useState } from 'react';

import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import ArtDetailPage from './pages/ArtDetailPage';
import HomePage from './pages/HomePage';
import { Art } from './types';


const initialArtList: Art[] = [
  { id: 1, title: 'Brain', artist: 'Kathy Su', image: '/images/202412/brain.jpg' },
  { id: 2, title: 'Coke Flower', artist: 'Kathy Su', image: '/images/202412/coke_flower.jpg' },
  { id: 3, title: 'Pencil Static Object', artist: 'Kathy Su', image: '/images/202412/pencil_static_objects.jpg' },
  { id: 4, title: 'Sandwich', artist: 'Kathy Su', image: '/images/202412/sandwich.jpg' },
  { id: 5, title: 'Shoes', artist: 'Kathy Su', image: '/images/202412/shoes.jpg' },
  { id: 6, title: 'Skeleton', artist: 'Kathy Su', image: '/images/202412/skeleton.jpg' },
  // Add more art pieces here
];

const App: React.FC = () => {
  const [artList] = useState<Art[]>(initialArtList); // Using state for artList
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage artList={artList} />} /> {/* Pass artList as a prop */}
        <Route path="/art/:id" element={<ArtDetailPage artList={artList} />} /> {/* Pass artList as a prop */}
      </Routes>
    </Router>
  );
};

export default App;