import { Container, Grid2, Typography } from '@mui/material';
import React from 'react';
import ArtCard from '../components/ArtCard';
import Copyright from '../components/Copyright';
import { Art } from '../types';

interface HomePageProps {
    artList: Art[]; // This prop is passed from the App component to HomePage component.
}

const HomePage: React.FC<HomePageProps> = ({ artList }) => {

    return (
        <Container>
            <Typography variant="h4" component="h1" gutterBottom>
                Framed Ghosts - Arts of the future
            </Typography>
            <Grid2 container spacing={4} > {
                artList.map((art) => (
                    <Grid2 key={art.id} columns={{ xs: 4, sm: 8, md: 12 }} component="div">
                        <ArtCard art={art} />
                    </Grid2>))}
            </Grid2>
            <Typography>
                <Copyright />
            </Typography>
        </Container>
    );
};

export default HomePage;
